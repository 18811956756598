import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import ServiceHero from '@/components/ServiceHero';
import ServiceLinkItem from '@/components/ServiceLinkItem';

import { SERVICE_DATA } from '@/referentials/routes';

export default function UxUiDesignerPage({ data }) {
  const { t } = useTranslation();

  const contentUxUi = {
    name: t('uiuxTitle'),
    bg: 'bg-primaryLighter',
    blobFirstColor: '#AEA2D3',
    blobSecondColor: '#C8BAF4',
    content: t('uiuxContent'),
    nextPage: t('uiuxNextPage'),
    tags: [
      t('tagWireframe'),
      t('tagUX'),
      t('tagFigma'),
      t('tagAB'),
      t('tagAtomicDesign'),
      t('tagSystemDesign'),
    ],
  };

  return (
    <Layout
      title="Services: UX/UI Design"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
      className="mt-8"
    >
      <ServiceHero tags={contentUxUi} img={data.paint} />
      <ServiceLinkItem
        title={`${t('next')} ${t('dataTitle')}`}
        to={SERVICE_DATA}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["services", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    paint: file(relativePath: { eq: "ux-ui.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
